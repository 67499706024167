import { render, staticRenderFns } from "./NotFoundPage.vue?vue&type=template&id=16bc0007&scoped=true&"
import script from "./NotFoundPage.vue?vue&type=script&lang=js&"
export * from "./NotFoundPage.vue?vue&type=script&lang=js&"
import style0 from "./NotFoundPage.vue?vue&type=style&index=0&id=16bc0007&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16bc0007",
  null
  
)

export default component.exports