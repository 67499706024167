<template>
  <div class="page-not-found">
    <center>
      <h1>404 not found</h1>
      <h2>it seems you're in the wrong page</h2>
    </center>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: 'Page Not Found'
    }
  }
}
</script>

<style lang="sass" scoped>
.page-not-found
  center
    height: 100vh
    h1
      margin: 50px 0  
</style>
